import * as React from 'react'
import { useAuth } from 'components/auth/auth'
import { PRODUCT_TYPE } from 'enum/product-type'
import { IOnboardingFlowLineItemRequest } from 'interfaces/onboarding-flow'
import { IOrderResponse, TLineItemPrescription, TLineItemStripe } from 'interfaces/order'
import * as Sentry from '@sentry/nextjs'
import { fetchBackend } from '@/lib/api/backend'

interface IRefillOrderBody {
    customer_email: string
    requested_prescriptions: {
        prescription_id: string
        quantity: number
        acknowledge_allow_stack_fills: boolean
    }[]
    // for now we assume this is true and only
    // allow user to click on the checkout button if consented
    // else we'd have to generate an orderDetails everytime these consents change
    acknowledge_not_crc_agreement: true
    authorization_for_use_and_disclosure_agreement: true
    acknowledge_allow_stack_fills: boolean
    payment_processor: 'shopify'
}

export interface IOnboardingOrderBody {
    requested_prescriptions: {
        drug: string
        requested_quantity: number
        day_supply: number | undefined
        pills_per_day: number | undefined
        total_pills_direct_input: number | undefined
        first_name: string
        last_name: string
        date_of_birth: string
        email: string
    }[]
    allergies: string[]
    line_items: IOnboardingFlowLineItemRequest[]
    state: string
    sex_assigned_at_birth: string
    email: string
    payment_processor: 'stripe'
}

type TOrderCreationState = 'idle' | 'pending' | 'success' | 'error'

export interface IReturn extends IOrderResponse {
    createOrder: (details: IOnboardingOrderBody | IRefillOrderBody) => void
    totalPrice: number | undefined
    subTotalPrice: number | undefined
    shippingPriceAfterDiscount: number | undefined
    isFreeShipping: boolean
    orderCreationState: TOrderCreationState
    membershipDiscount: number | undefined
}

export function useOrder(orderProp?: IOrderResponse): IReturn {
    const { idToken } = useAuth()

    const [order, setOrder] = React.useState<IOrderResponse>()
    const [orderCreationState, setOrderCreationState] = React.useState<TOrderCreationState>('idle')

    /**
     * Creates an order by calling the backend and updates the different
     * states of the hook
     */
    const createOrder = React.useCallback(
        async (details: IOnboardingOrderBody | IRefillOrderBody) => {
            const prescriptionLineItemsLen = details.requested_prescriptions.length
            const otcLineItemsLen = details['line_items']?.length ?? 0

            const totalLineItems = prescriptionLineItemsLen + otcLineItemsLen

            if (totalLineItems === 0) {
                setOrder(undefined)
                setOrderCreationState('idle')
                return
            }

            try {
                setOrderCreationState('pending')
                const response = await fetchBackend('/api/rx/order/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${idToken}`,
                    },
                    body: JSON.stringify(details),
                })

                if (!response.ok) {
                    setOrderCreationState('error')
                } else {
                    const responseJSON = await response.json()

                    setOrder(responseJSON)
                    setOrderCreationState('success')
                }
            } catch (e) {
                Sentry.captureException(e)
                setOrderCreationState('error')
            }
        },
        [idToken],
    )

    const { line_items, total_price, shipping_price, shipping_discount } = order ?? orderProp ?? {}

    const shippingPriceAfterDiscount = Number(shipping_price) - Number(shipping_discount)
    const isFreeShipping = shippingPriceAfterDiscount === 0

    // group line items based on product/drug id
    // line items can repeat in an order, show the repeated line items
    // as a single line item with the total quantity
    const lineItemsMap = new Map<string, TLineItemStripe>()

    line_items?.forEach((lineItem, index) => {
        // do not merge in case of prescription
        if (lineItem.type === PRODUCT_TYPE.PRESCRIPTION) {
            lineItemsMap.set(String(index), { ...lineItem })
            return
        }

        const key = lineItem.product_id

        if (!lineItemsMap.has(key)) {
            lineItemsMap.set(key, { ...lineItem })
        } else {
            const existingLineItem = lineItemsMap.get(key)
            existingLineItem.quantity += lineItem.quantity
        }
    })

    const { memDis: membershipDiscount, subTotal: subTotalPrice } = line_items?.reduce(
        ({ memDis, subTotal }, lt) => {
            if (lt.type !== PRODUCT_TYPE.PRESCRIPTION) {
                return {
                    memDis,
                    subTotal: subTotal + Number(lt.total_price),
                }
            }

            // todo: explore how types can be setup so TS can infer the type
            lt = lt as TLineItemPrescription

            const discount = Number(lt.standard_total_price) - Number(lt.total_price)

            return {
                memDis: (memDis ?? 0) + discount,
                subTotal: (subTotal ?? 0) + Number(lt.standard_total_price),
            }
        },
        {
            memDis: 0,
            subTotal: 0,
        },
    ) ?? { memDis: undefined, subTotal: undefined }

    return {
        ...order,
        line_items: [...lineItemsMap.values()],
        shippingPriceAfterDiscount,
        totalPrice: typeof total_price !== 'undefined' ? Number(total_price) : total_price,
        subTotalPrice,
        isFreeShipping,
        orderCreationState,
        createOrder,
        membershipDiscount,
    }
}
