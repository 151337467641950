import * as React from 'react'
import { IPrescriptionLineItem } from 'interfaces/prescription-line-item'
import { IUiResources } from 'interfaces/ui-resource'
import { useRXCartContext } from 'context/rx-cart-context'
import { getDrugNameWithStrength } from '@/lib/util/prescription'
import CartLineItem from './cart-line-item'
import { TLineItemPrescription } from 'interfaces/order'

interface IProps {
    lineItem: IPrescriptionLineItem
    uiResources: IUiResources
}

export function CartRxLineItem({ uiResources, lineItem }: IProps): React.ReactElement {
    const { removeLineItem, orderCreationState, line_items } = useRXCartContext()

    // find corresponding line item from the order
    const correspondingLineItemInOrder = line_items?.find((lt) => {
        if (!lt['drug_id']) {
            return false
        }

        return lt['drug_id'] === lineItem.drug.id
    }) as TLineItemPrescription

    const { total_price, standard_total_price } = correspondingLineItemInOrder ?? {}

    return (
        <CartLineItem
            image={uiResources['rxCartLineItemImage'].media}
            title={getDrugNameWithStrength({ drug: lineItem.drug })}
            price={Number(total_price)}
            onRemove={() => removeLineItem(lineItem.id)}
            loading={orderCreationState === 'pending'}
            body={<span>{lineItem.requestedQuantityWithLabel}</span>}
            isAvailable
            compareAtPrice={standard_total_price}
        />
    )
}
