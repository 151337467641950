// which don't fit else where

export const productPageTypename = 'ProductPage'
export const userSubscribeEventName = 'Subscribe'
export const pageSectionIconsContentTypename = 'PageSectionIcons'
export const preFooterIconSectionInternalName = 'Pre Footer'
export const pageSectionComparisonTableContentTypename = 'PageSectionComparisonTable'
export const pageSectionTestimonialsTypename = 'PageSectionTestimonials'
export const pageSectionVisualizerContentTypename = 'PageSectionVisualizer'
export const pageSectionGenericTypename = 'PageSectionGeneric'
export const pageSectionProjectTypename = 'PageSectionProject'
export const pageSectionFormTypename = 'PageSectionForm'
export const pageSectionHTMLTypename = 'PageSectionHtml'
export const pageSectionAccordionTypename = 'PageSectionAccordion'
export const medicalReviewTypename = 'MedicalReview'
export const pageSectionProductsTypename = 'PageSectionProducts'
export const pageSectionProductCategoriesTypename = 'PageSectionProductCategories'

export const minThresholdToShowRating = 4.1
export const ratingOutOf = 5
export const popupSeenLSKey = 'user_has_seen_popup'
export const shopifyGlobalIdPrefix = 'gid://shopify/Product/'
export const notAllowedErrorName = 'NotAllowedError'
export const sustainabilitySitePageInternalName = 'Our Mission v2'
export const sustainabilitySitePageSlug = 'our-mission'
export const sustainableSwapTourPageSlug = 'target-sustainability-swap-campaign'
export const theoryOfChangeSectionInternalName = 'Theory of Change'
export const accountPageOrdersTabKey = 'account_portal_orders_tab_title'
export const accountPageMedicinesTabKey = 'account_portal_medicines_tab_title'
export const accountPageProfileTabKey = 'account_portal_profile_tab_title'
export const accountPageArchiveTabKey = 'account_portal_archive_tab_title'
export const accountPageArchiveTabInternalName = 'account_archive'
export const accountPageOrdersTabInternalName = 'account_orders'
export const accountPageMedicinesTabInternalName = 'account_medicines'
export const accountPageProfileTabInternalName = 'account_profile'
export const accountPageSidebarLogoutButtonCommonCopyKey = 'logoutButtonLabelWithIcon'
export const rxRefillDaySupplyInputInternalName = 'Rx - Refills - Medicine Day Supply'
export const rxRefillPillsPerDayInputInternalName = 'Rx - Refills - Medicine - Per day '
export const rxRefillStackFillsAuthorizationFormInputInternalName = 'Rx - Refills - Stack fills authorization'
export const rxRefillTotalPillsDirectInputInternalName = 'Rx - Refills - Total pills'
export const rxRefillAsNeededInputInternalName = 'Rx - Refills - Is as needed'
export const rxRefillNotChildSafeConsent = 'Rx - Refills - Not Child Safe Consent'
export const blogVisualizerTextBubblesEntryInternalName = 'How We Stack Up'

// Experiment feature flag names and related variables
export const customizerProductCardsExperimentName = 'customizer-product-cards'
export const priceElasticityExperimentName = 'price-elasticity-experiment'
export const priceElasticityExperimentControlVariationName = 'price-variation-control'
export const accountPortalHeroExperimentName = 'account-portal-hero'
export const websiteSubscribeOrderExperimentName = 'website-subscribe-sms-email-order'
export const websiteSubscribeOrderEmailFirstVariation = 'email-first'
export const websiteSubscribeOrderPhoneFirstVariation = 'phone-first'
export const websiteSubscribeFullScreenExperimentName = 'website-subscribe-full-screen'
export const disableBundleCustomizationExperimentName = 'disable-bundle-customization'
export const showRxPDPFormSubmitButtonExperimentName = 'show-rx-pdp-form-submit-button'
export const showDetailedPersonalizedCapExperimentName = 'show-detailed-personalized-cap'
export const shippingThresholdExperimentName = 'shipping-threshold-land-exp'
export const shippingThresholdExperimentVariationOne = 'FREESHIP30'
export const shippingThresholdExperimentVariationTwo = 'FREESHIP40'
export const rxHomeHeroExperimentName = 'rx-home-hero'
export const rxSignUpLineItemsFeatureName = 'rx-sign-up-line-items'
export const rxFlowStepsFeatureName = 'rx-flow-steps'
export const priceInRxSearchCTAFeatureName = 'price-in-rx-search-cta'
export const telehealthFlowFeatureName = 'telehealth-flow'
export const telehealthFlowExperimentTestVariationName = 'telehealth-flow-test'
export const rxFlowInformationalVideoFeatureName = 'rx-flow-informational-video'
export const rxFlowInformationalVideoExperimentTestVariationName = 'rx-flow-informational-video-test'
export const rxFlowHideSubtotalFeatureName = 'rx-flow-hide-subtotal'
export const rxFlowHideSubtotalExperimentTestVariationName = 'rx-flow-hide-subtotal-test'
export const plasticCalculatorCTAFeatureName = 'plastic-calculator-cta'
export const hideBlogPostPopupFeatureName = 'hide-popup-on-blog-posts'
export const rxHeroCopyTestFeatureName = 'rx-hero-copy'

// tags in Contentful
export const medicineProductPageContentfulTag = 'userInterfaceMedicineProductPage'
export const kitProductPageContentfulTag = 'userInterfaceKitProductPage'
export const genericProductPageContentfulTag = 'userInterfaceGenericProductPage'
export const refillableProductPageContentfulTag = 'userInterfaceRefillableProductPage'
export const collectionPageContentfulTag = 'userInterfaceCollectionPage'
export const medicineQRPageContentfulTag = 'userInterfaceMedicinePage'
export const rxProductPageContentfulTag = 'userInterfaceRxProductPage'

export const heroSectionRenderTypeWhichRendersRxSearchForm = 'content left, search center, large image right'

export const onboardingFlowFirstNameQueryParamName = 'first_name'
export const onboardingFlowLastNameQueryParamName = 'last_name'
export const onboardingFlowEmailQueryParamName = 'email'
export const onboardingFlowPhoneNumberQueryParamName = 'phone_number'
export const onboardingFlowFullCapNameQueryParamName = 'token_name'

// pharmacies
export const healthHavenPharmacyName = 'HealthHavenRX'
export const coverMyMedsPharmacyName = 'CoverMyMeds'

// storage keys
export const rxSignupDetailsNonCtxStorageKey = 'rx-signup-details-non-ctx'
export const rxSignupDetailsCtxStorageKey = 'rx-signup-details-ctx'

// links
export const tokenRxFlowBackgroundImage =
    'https://images.ctfassets.net/p2eqd3iabyva/2n6SytCI4gTsYsXSnGf6MO/3bf883b5f1a503ca278902d2b6325e09/rx-bottle-cap-top-blank.png?q=90&w=1000'
export const tokenSectionBackgroundImage =
    'https://images.ctfassets.net/p2eqd3iabyva/1MnS7y0M6t59hveL6RPGmY/4079ce9e79dc833fc5411b906d3d70d0/rx-bottle-cap-top-blank.png?q=90&w=1000'
